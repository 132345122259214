import { DateTime } from "luxon"

const getTime = (str) => {
    var dt = DateTime.fromISO(str);
    return dt.toFormat("HH:mm")
}
const getDateTime = (str) => {
    return DateTime.fromISO(str);
}
const getDateStr = (str) => {
    var dt = getDateTime(str)
    return formatDateTime(dt)
}
const getDateLiteralStr = (str) => {
    var dt = getDateTime(str)
    return format(dt, "DDDD")
}

const format = (dt, str) =>{
    return dt.toFormat(str);
}
const formatDateTime = (dt) =>{
    return dt.toFormat("dd/MM/yyyy")
}

export {
    getTime,
    getDateTime,
    getDateStr,
    formatDateTime,
    getDateLiteralStr
}