import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import * as styles from "./question-types.module.scss"

export default function QuestionOptions({
  structureI,
  optionList,
  answerResponse,
  values,
  valueIndex,
  updateAnswerValue,
}) {
  
  const update = (value) => {
    if (answerResponse?.isAutoMarked !== true) {
      updateAnswerValue(valueIndex, value)
    }
  }

  const active = (i) => {
    return values ? values[valueIndex] == (i + 1).toString() : false
  }

  return (
    <>
      <div style={answerResponse?.isAutoMarked ? { opacity: 0.5 } : {}}>
        {optionList.map((o, i) => (
          <>
            <div
              className={`${styles.qBooleanOption} ${
                active(i) ? styles.active : ""
              }`}
              key={`${structureI}_${i + 1}`}
              onClick={() => update(i + 1)}
            >
              <input
                hidden
                disabled={answerResponse?.isAutoMarked}
                type="checkbox"
                data-answer-index={i}
                checked={active(i)}
                data-answer-value={i + 1}
              />

              <div className={styles.check}>
                {active(i) ? <FontAwesomeIcon icon={faCheck} /> : ""}
              </div>

              <div>{o.value}</div>
            </div>
          </>
        ))}
      </div>
    </>
  )
}
