import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import ClassroomLayout from "../layout/classroom-layout"
import { Col, Container, Row, Tab } from "react-bootstrap"
import AuthService from "../../../services/AuthService"
import ApiService from "../../../services/ApiService"
import Question from "./question"
import ModuleQuestionsNav from "./module-questions-nav"
import TestReview from "./review"
import Loader from "../shared/loaders"
import Breadcrumbs from "../shared/breadcrumb"
import { useDispatch } from "react-redux"
import { setContextSidebar } from "../../../redux/app-actions"

export default function Test(props) {

  const dispatch = useDispatch();

  const { pageContext } = props
  const { course, moduletest, breadcrumb } = pageContext

  const Test = props.data.scocms.getExamPaper.data

  const [loaded, setLoaded] = useState(false)
  const [, setError] = useState(true)

  // build time load questions
  let _qs = []
  for (const [i, q] of Test.questions.entries()) {
    _qs.push({ q, i, disabled: i !== 0 })
  }

  const [uep, setUep] = useState(null)
  const [questions, setQuestions] = useState(_qs)
  const [currentTab, setCurrentTab] = useState(null)

  useEffect(async () => {
    dispatch(setContextSidebar(null))
    if (Test.questions != null) {
      const _qs = [...questions]

      let uep = {}

      const user = await AuthService.getCurrentUser()

      if (user) {
        uep = {
          cognitoID: user.username,
          examPaperID: Test.id,
          examID: moduletest,
          numberOfQuestions: Test.questions.length,
        }

        uep = await ApiService.startExamPaper(uep)

        setUep(uep)

        const answerCount = uep?.answers?.items?.length ?? 0

        if (uep) {
          //load
          if (uep?.answers?.items) {
            uep.answers.items.forEach((answer, answerI) => {
              var io = _qs.findIndex(_q => _q.q.id === answer.questionID)
              var question = _qs[io]
              if (question != null) {
                _qs[io] = {
                  ...question,
                  answerResponse: answer,
                  disabled: false,
                }
              }

              if (answerI + 1 === answerCount) {
                // enable next q
                var nextQ = _qs[answerI + 1]
                if (nextQ) {
                  _qs[answerI + 1] = {
                    ...nextQ,
                    disabled: false,
                  }
                }
              }
            })
          }
          setQuestions([])
          setQuestions(_qs)
          setCurrentTab(_qs[answerCount == 0 ? 0 : answerCount - 1].q.id)
          setLoaded(true)
          setError(false)
        }
      }
    }
  }, [])

  const handleAnswer = (e, answerResponse, uep) => {
    let _questions = [...questions]

    for (const [i, q] of Test.questions.entries()) {
      if (q.id === e.question) {
        // set answerResponse
        var currentQ = Test.questions[i]
        _questions.forEach(item => {
          if (item.q.id === currentQ.id) {
            item.answerResponse = answerResponse
          }
        })

        // enable next q
        if (i + 1 <= Test.questions.length - 1) {
          var nextQ = Test.questions[i + 1]
          _questions.forEach(item => {
            if (item.q.id === nextQ.id) {
              item.disabled = false
            }
          })
        }
      }
    }

    setUep(uep)

    setQuestions([])
    setQuestions(_questions)
  }

  const handleButtonClick = type => {
    switch (type) {
      case "next":
        for (const [i, q] of Test.questions.entries()) {
          if (q.id === currentTab) {
            var nextTab = questions[i + 1]
            if (nextTab && !nextTab.disabled) {
              setCurrentTab(nextTab.q.id)
            } else{
              if(uep.isFinished){
                setCurrentTab("review")
              }
            }
          }
        }

        break
      case "prev":
        for (const [i, q] of Test.questions.entries()) {
          if (q.id === currentTab) {
            var prevTab = questions[i - 1]
            if (prevTab && !prevTab.disabled) {
              setCurrentTab(prevTab.q.id)
            }
          }
        }

        break
      case "end":
        navigate(`/classroom/${course}`)
        break
    }
  }

  return (
    <ClassroomLayout>
      {loaded ? (
        <Container>
          <div className="my-4">
              <Breadcrumbs links={breadcrumb} />
          </div>

          <div>
            <h1>{Test.name}</h1>
            <h3>{Test.pageInstructions}</h3>
          </div>

          <ModuleQuestionsNav
            tabs={questions}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            showReviewTab={uep?.isFinished}
          />

          <Tab.Container id="questions" defaultActiveKey={Test.questions[0].id}>
            <Row>
              <Col>
                <Tab.Content>
                  {questions.map(itemq => (
                    <Tab.Pane
                      active={itemq.q.id === currentTab}
                      key={itemq.i}
                      eventKey={itemq.q.id}
                    >
                      <Question
                        question={itemq}
                        uep={uep}
                        questionCount={questions.length}
                        handleAnswer={handleAnswer}
                        handleButtonClick={handleButtonClick}
                      />
                    </Tab.Pane>
                  ))}
                  {uep?.isFinished ? (
                    <Tab.Pane
                      active={"review" === currentTab}
                      key={"review"}
                      eventKey={"review"}
                    >
                      <TestReview
                        uep={uep}
                        quit={() => handleButtonClick("end")}
                      />
                    </Tab.Pane>
                  ) : (
                    ""
                  )}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      ) : (
        <Loader height={"70vh"} />
      )}
    </ClassroomLayout>
  )
}

export const query = graphql`
  query ($id: ID) {
    scocms {
      getExamPaper(where: { id: $id }) {
        data {
          id
          name
          isTimed
          time
          type
          questions {
            id
            type {
              name
              code
              configuration
              defaultValue
              displayFormat
            }
            text
            options
            information
            showWorkings
            showUploads
            instructorTips
            ref
            availableScore
          }
        }
      }
    }
  }
`
