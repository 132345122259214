import React from "react"
import { Nav } from "react-bootstrap"
import * as styles from "./module-questions-nav.module.scss"

export default function ModuleQuestionsNav({
  tabs,
  currentTab,
  setCurrentTab,
  showReviewTab,
}) {
  const getCardClass = question => {
    if (question.answerResponse?.answerScore != null) {
      if (question.answerResponse?.answerScore === question?.q?.availableScore)
        return styles.green
      if (question.answerResponse?.answerScore === 0) return styles.red
      return styles.amber
    }
    return ""
  }
  return (
    <>
      <div>
        <Nav>
          {tabs.map(item => (
            <>
              {!item.disabled ? (
                <Nav.Link
                  className={`${styles.itemLink} ${getCardClass(item)} ${
                    currentTab === item.q.id ? styles.active : ""
                  }`}
                  key={item.q.id + "_link"}
                  eventKey={item.q.id}
                  disabled={item.disabled}
                  onClick={() => setCurrentTab(item.q.id)}
                >
                  <div className={styles.item}>{item.i + 1}</div>
                </Nav.Link>
              ) : (
                ""
              )}
            </>
          ))}
          {showReviewTab ? (
            <Nav.Link
              className={`${styles.itemLink} ${styles.review} ${
                currentTab === "review" ? styles.active : ""
              }`}
              key={"review_link"}
              eventKey={"review_link"}
              onClick={() => setCurrentTab("review")}
            >
              <div className={styles.item}>Review</div>
            </Nav.Link>
          ) : (
            ""
          )}
        </Nav>
      </div>
    </>
  )
}
