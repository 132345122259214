import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RichTextRenderer } from "@webiny/react-rich-text-renderer"
import React from "react"
import { Card, Alert, Row, Col, Button } from "react-bootstrap"
import QuestionType from "./question-type"
import * as styles from "./question.module.scss"

export default function Question({
  question,
  questionCount,
  uep,
  handleAnswer,
  handleButtonClick,
}) {

  const answer = (e, values, answerResponse, paper) => {
    handleAnswer(e, answerResponse, paper)
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title className={styles.questionNumber}>
            Question {question.i + 1} of {questionCount}
          </Card.Title>
          {question?.q?.information && question?.q?.information != "" ? (
            <Alert variant="info">
              <RichTextRenderer data={question.q.information} />
            </Alert>
          ) : (
            ""
          )}
          <div className={styles.questionText}>
            <RichTextRenderer data={question.q.text} />
          </div>
        </Card.Body>
      </Card>

      <Row className="mt-4">
        <Col xs="12" md="6">
          
          <QuestionType
            key={question.i}
            _questionIndex={question.i}
            _userExamPaper={uep}
            question={question}
            data={question.q.type}
            options={question.q.options}
            answer={answer}
          />

          <div className="my-4">
            <Button className="me-2" onClick={() => handleButtonClick("prev")}>
              <FontAwesomeIcon className="me-2" icon={faArrowCircleLeft} />
              Previous
            </Button>
            <Button className="me-2" onClick={() => handleButtonClick("next")}>
              Next
              <FontAwesomeIcon className="ms-2" icon={faArrowCircleRight} />
            </Button>
          </div>
          <div className="mt-2">
            {/* <Button
              variant="secondary"
              onClick={() => handleButtonClick("end")}
            >
              Quit Module Test
            </Button> */}
          </div>
        </Col>
        <Col xs="12" md="6">
          {question?.answerResponse ? (
            <Card className="mb-5">
              <Card.Body>
                <Card.Title>Correct Answer</Card.Title>
                <span>{question.answerResponse.answerModelAnswerText}</span>
                {question.answerResponse.instructorComments != "" &&
                question.answerResponse.instructorComments ? (
                  <>
                    <RichTextRenderer data={question.answerResponse.instructorComments} />
                  </>
                ) : (
                  ""
                )}
              </Card.Body>
            </Card>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  )
}
