import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import * as styles from "./question-types.module.scss"

export default function QuestionBoolean({
  i,
  answerResponse,
  valueIndex,
  updateAnswerValue,
  options,
}) {
  
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (value !== null) {
      updateAnswerValue(valueIndex,value)
    }
  }, [value])

  useEffect(() => {
    if(answerResponse?.answerScore != null){
      setValue(JSON.parse(answerResponse.answerValue)[0]);
    }
  }, [answerResponse])
  
  const set = (value) =>{
    if(answerResponse?.isAutoMarked !== true){
      setValue(value)
    }
  }

  return (
    <>
      <div style={answerResponse?.isAutoMarked ? { opacity: 0.5 } : {}}>
        {options.map(o => (
          <div
            className={`${styles.qBooleanOption} ${
              value === o.value.toString() ? styles.active : ""
            }`}
            key={`${i}_${o.value}`}
            onClick={() => set(o.value.toString())}
          >
            <div className={styles.check}>
              {value === o.value.toString() ? <FontAwesomeIcon icon={faCheck} /> : ""}
            </div>
            <div>{o.label}</div>
          </div>
        ))}
      </div>
    </>
  )
}
