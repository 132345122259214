import React from "react"
import * as styles from "./loader.module.scss"

export default function Loader({ height }) {
  height = height ?? "auto";
  return (
    <div className="d-flex justify-content-center align-items-center" style={{height}}>
      <div className={styles.loader}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
