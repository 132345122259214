import React from "react"
import QuestionCheckboxItem from "./q-checkbox-item"

export default function QuestionCheckboxes({
  structureI,
  optionList,
  answerResponse,
  values,
  updateAnswerValue,
}) {
  const update = (valueIndex, value) => {
    if (answerResponse?.isAutoMarked !== true) {
      if(values[valueIndex] === value){
        value = null;
      }
      updateAnswerValue(valueIndex, value)
    }
  }

  return (
    <>
      <div style={answerResponse?.isAutoMarked ? { opacity: 0.5 } : {}}>
        {optionList.map((o, i) => (
          <QuestionCheckboxItem key={`${structureI}_${i}`} values={values} i={i} item={o} structureI={i} answerResponse={answerResponse} update={update}/>
        ))}
      </div>
    </>
  )
}
