import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import * as styles from "./question-types.module.scss"

export default function QuestionCheckboxItem({
  values,
  i,
  item,
  structureI,
  answerResponse,
  update,
}) {

  const value = (i + 1).toString();

  return (
    <div
      className={`${styles.qBooleanOption} ${
        values.includes(value) ? styles.active : ""
      }`}
      key={`${structureI}_${value}`}
      onClick={() => update(i, value)}
    >
      <input
        hidden
        disabled={answerResponse?.isAutoMarked}
        type="checkbox"
        checked={values ? values.includes(value) : false}
      />

      <div className={styles.check}>
        {values.includes(value) ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          ""
        )}
      </div>

      <div>{item.value}</div>
    </div>
  )
}
