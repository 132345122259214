import { faCheck, faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Button, Card } from "react-bootstrap"
import ApiService from "../../../services/ApiService"
import QuestionBoolean from "./question-types/q-boolean"
import QuestionCheckboxes from "./question-types/q-checkboxes"
import QuestionOptions from "./question-types/q-options"

export default function QuestionType({
  _questionIndex,
  _userExamPaper,
  question,
  data,
  options,
  answer,
}) {
  const qtype = JSON.parse(data.configuration)
  const [userExamPaper, setUserExamPaper] = useState({})
  let optionList = null
  if (options) {
    optionList = JSON.parse(options)
  }
  let lstType = []

  const [values, setValues] = useState([])
  const [questionIndex, setQuestionIndex] = useState(0)

  const [submiting, setSubmitting] = useState(false);

  useEffect(() => {
    if (question?.answerResponse?.answerValue) {
      setValues(JSON.parse(question.answerResponse.answerValue))
    }
  }, [question])

  useEffect(() => {
    setQuestionIndex(_questionIndex)
    setUserExamPaper(_userExamPaper)
  }, [])

  const onAnswer = async e => {
    setSubmitting(true);
    const vs = values.filter(v => v).map(v => v.toString())

    let studentAnswer = {
      answerModelAnswerValue: "",
      answerScore: 0,
      answerText: "",
      answerValue: { values: vs },
      cognitoID: userExamPaper.cognitoID,
      examPaperID: userExamPaper.examPaperID,
      questionID: e.question,
      questionType: qtype.code,
      userExamPaperID: userExamPaper.id,
      instructorComments: [],
      isAutoMarked: true,
      answerWorkings: "",
      questionIndex: questionIndex,
    }

    const paper = await ApiService.markQuestion(studentAnswer)

    if (paper) {
      const ar = paper.answers.items.find(i => i.questionID == e.question);
      answer(e, values, ar, paper)
    }
    
    setSubmitting(false);
  }

  const updateAnswerValue = (i, v) => {
    let _vs = [...values]
    _vs[i] = v
    setValues(_vs)
  }

  const getCardClass = () => {
    if (question.answerResponse?.answerScore != null) {
      if (question.answerResponse?.answerScore === question?.q?.availableScore)
        return "green"
      if (question.answerResponse?.answerScore === 0) return "red"
      return "amber"
    }
    return ""
  }
  const getCardIcon = () => {
    if (question.answerResponse?.answerScore != null) {
      if (question.answerResponse?.answerScore === question?.q?.availableScore)
        return <FontAwesomeIcon icon={faCheck} />
      if (question.answerResponse?.answerScore === 0) return <FontAwesomeIcon icon={faTimes} />
      return <FontAwesomeIcon icon={faCheck} />
    }
    return ""
  }

  let valueIndex = 0
  for (let [i, t] of qtype.structure.entries()) {
    let OPTIONS
    switch (t.type) {
      case "select":
        var lstOptions = []
        if (t.values != null) {
          for (const [k, s] of t.values.entries()) {
            lstOptions.push(
              <option key={i + "" + k} value={s}>
                {s}
              </option>
            )
          }
        }
        if (t.from != null) {
          for (let x = t.from; x < t.to + 1; x++) {
            lstOptions.push(
              <option key={i + "" + x} value={x}>
                {x}
              </option>
            )
          }
        }
        lstType.push(
          <select
            key={i}
            disabled={question?.answerResponse?.isAutoMarked || submiting}
            data-answer-index={valueIndex}
            onChange={e => {
              updateAnswerValue({
                ...values,
                [e.target.getAttribute("data-answer-index")]: e.target.value,
              })
            }}
          >
            {lstOptions}
          </select>
        )

        valueIndex += 1
        break
      case "label":
        lstType.push(<span key={i}>{t.value}</span>)
        break
      case "text":
        if (t.multiline) {
          lstType.push(
            <textarea
              key={i}
              disabled={question?.answerResponse?.isAutoMarked || submiting}
              rows="4"
              cols="30"
              data-answer-index={valueIndex}
              onChange={e => {
                updateAnswerValue({
                  ...values,
                  [e.target.getAttribute("data-answer-index")]: e.target.value,
                })
              }}
            />
          )
        } else {
          // if e.multi
          lstType.push(
            <input
              key={i}
              disabled={question?.answerResponse?.isAutoMarked || submiting}
              data-answer-index={valueIndex}
              onChange={e => {
                updateAnswerValue({
                  ...values,
                  [e.target.getAttribute("data-answer-index")]: e.target.value,
                })
              }}
            />
          )
        }
        valueIndex += 1
        break
      case "radiobuttonlist":
        var lstRadioOptions = []
        if (optionList) {
          lstRadioOptions = (
            <QuestionOptions
              structureI={i}
              optionList={optionList}
              answerResponse={question?.answerResponse}
              values={values}
              valueIndex={valueIndex}
              updateAnswerValue={updateAnswerValue}
            />
          )
        }
        lstType.push(
          <div key={i} data-answer-index={valueIndex}>
            {lstRadioOptions}
          </div>
        )
        valueIndex += 1
        break
      case "checkboxlist":
        var lstCheckBoxOptions = []

        lstCheckBoxOptions = (
          <QuestionCheckboxes
            structureI={i}
            optionList={optionList}
            answerResponse={question?.answerResponse}
            values={values}
            updateAnswerValue={updateAnswerValue}
          />
        )
        valueIndex += 1

        lstType.push(<div key={i}>{lstCheckBoxOptions}</div>)
        break
      case "yesno":
        OPTIONS = [
          {
            label: "Yes",
            value: "yes",
          },
          {
            label: "No",
            value: "no",
          },
        ]

        lstType.push(
          <>
            <QuestionBoolean
              i={i}
              values={values}
              answerResponse={question?.answerResponse}
              valueIndex={valueIndex}
              updateAnswerValue={updateAnswerValue}
              options={OPTIONS}
            />
          </>
        )

        valueIndex += 1
        break
      case "truefalse":
        OPTIONS = [
          {
            label: "True",
            value: true,
          },
          {
            label: "False",
            value: false,
          },
        ]

        lstType.push(
          <>
            <QuestionBoolean
              i={i}
              values={values}
              answerResponse={question?.answerResponse}
              valueIndex={valueIndex}
              updateAnswerValue={updateAnswerValue}
              options={OPTIONS}
            />
          </>
        )
        valueIndex += 1
        break
      default:
        lstType.push(
          <input
            key={i}
            disabled={question?.answerResponse?.isAutoMarked || submiting}
            data-answer-index={valueIndex}
            onChange={e => {
              updateAnswerValue({
                ...values,
                [e.target.getAttribute("data-answer-index")]: e.target.value,
              })
            }}
          />
        )
        valueIndex += 1
        break
    }
    i += 1
  }

  lstType.push(
    <>
      <div className="mt-2 text-end">
        <Button
          key={"submit" + question.q.id}
          onClick={() => onAnswer({ question: question.q.id })}
          disabled={question?.answerResponse?.isAutoMarked || submiting}
          style={{
            opacity: question?.answerResponse?.isAutoMarked ? 0 : 1,
          }}
        >
          Submit
          {submiting ? <FontAwesomeIcon icon={faSpinner} className="ms-2 fa-spin" /> : ""}
        </Button>
      </div>
    </>
  )

  return (
    <div>
      <Card className={`card-answer ${getCardClass()}`}>
        <Card.Body>
          <Card.Title>Your Answer <div className="icon">{getCardIcon()}</div></Card.Title>
          {lstType}
        </Card.Body>
      </Card>
    </div>
  )
}
