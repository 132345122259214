import React, { useEffect, useState } from "react"
import * as styles from "./review.module.scss"
import { Button, Card, Col, Row, Table } from "react-bootstrap"
import { getDateLiteralStr, getTime } from "../../../utils/date"
import { DateTime } from "luxon"

export default function TestReview({ uep, quit }) {
  const [time, setTime] = useState(null)

  useEffect(() => {
    if (uep) {
      setTime(
        DateTime.fromISO(uep.completedDate).diff(
          DateTime.fromISO(uep.startedDate),
          ["minutes", "seconds"]
        )
      )
    }
  }, [uep])

  return (
    <Card>
      <Card.Body>
        <div className={styles.review}>
          <Row>
            <Col xs="12" md={{ span: "auto", order: 2 }}>
              <div className={styles.percentage}>
                <div className={styles.title}>Your Score</div>
                <div>
                  <div className={styles.value}>
                    {parseInt(uep.percentageScore)}%
                  </div>
                </div>
              </div>
            </Col>
            <Col md={{ order: 1 }}>
              <div className={styles.details}>
                <h2>Test Completed</h2>

                <div className="my-4">
                  <Table>
                    <tbody>
                      <tr>
                        <th>Started:</th>
                        <td>
                          {getDateLiteralStr(uep.startedDate)}{" "}
                          {getTime(uep.startedDate)}
                        </td>
                      </tr>
                      <tr>
                        <th>Completed:</th>
                        <td>
                          {getDateLiteralStr(uep.completedDate)}{" "}
                          {getTime(uep.completedDate)}
                        </td>
                      </tr>
                      {time ? (
                        <tr>
                          <th>Time Spent:</th>
                          <td>
                            {time.minutes}m {time.seconds}s
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </Table>
                </div>

                <Button onClick={() => quit()}>Back to Dashboard</Button>
              </div>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  )
}
